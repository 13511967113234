<template>
    <el-dialog width="500px" top="40px" title="Välj period för kostnaden" :visible="visible" :close-on-click-modal="false" :before-close="closeModal" @open="modalOpen">
        <div class="flex flex-col py-10 px-10">
            <p class="text-lg mb-3">Account: {{ preselectedAccountNumber }}</p>
            <div class="flex space-x-4">
                <DateInputField ref="dateInputStartDate" :pre-selected-value="startDate" @change="startDate = $event" placeholder="Start datum" />
                <DateInputField @change="endDate = $event" placeholder="Slut datum" />
                <el-button @click="calculatePeriods" :disabled="!endDate" type="primary">Sök</el-button>
            </div>
            <div v-show="responsePeriod.amounts.length" class="mt-8">
                <div class="flex justify-between mb-2 px-2">
                    <p>Number of periods: {{ responsePeriod.numberOfAccruals }}</p>
                    <p>Belopp: {{ accrualSelectedAmount }}</p>
                </div>
                <table class="w-full border">
                    <tr>
                        <th class="py-2 px-3">Månad nr</th>
                        <th class="py-2 px-3 text-right" align="right">Belopp</th>
                    </tr>
                    <tr v-for="item in responsePeriod.amounts" :key="item.number" class="">
                        <td class="py-2 px-3">{{ item.number }}</td>
                        <td class="py-2 px-3 text-right" align="right">{{ item.amount }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">Stäng</el-button>
            <el-button type="primary" @click="save" :disabled="saveButtonDisabled">Välj</el-button>
        </span>
    </el-dialog>
</template>
<script>
import queryString from "query-string";
import Api from "../lopande.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        preselectedStartDate: {
            type: String,
            default: null,
        },
        preselectedAccountNumber: {
            type: Number,
            default: null,
        },
        clientId: {
            type: String,
            default: null,
        },
        accrualSelectedAmount: {
            type: Number,
            default: 0,
        },
    },
    components: {
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    data() {
        return {
            saveButtonDisabled: true,
            startDate: null,
            endDate: null,
            responsePeriod: {
                numberOfAccruals: 0,
                amounts: [],
            },
        };
    },

    methods: {
        async calculatePeriods() {
            const data = await Api.calculatePeriodAmount({
                clientId: this.clientId,
                amount: this.accrualSelectedAmount,
                costAccount: this.preselectedAccountNumber,
                startDate: this.startDate,
                stopDate: this.endDate,
            });
            this.responsePeriod = data;
            this.saveButtonDisabled = false;
        },
        modalOpen() {
            this.responsePeriod = {
                numberOfAccruals: 0,
                amounts: [],
            };

            this.saveButtonDisabled = true;
            const { startDate } = queryString.parse(window.location.search);
            this.startDate = this.preselectedStartDate || startDate;
            this.endDate = null;
        },
        closeModal() {
            this.$emit("close");
        },

        save() {
            this.$emit("update", {
                interimAccount: this.responsePeriod.interimAccount,
                transactionText: this.responsePeriod.transactionText,
                startDate: this.startDate,
                endDate: this.endDate,
            });
            this.closeModal();
        },
    },
};
</script>
<style scoped>
td {
    border: 1px solid #ddd;
}
</style>
